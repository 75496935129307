<template>
    <Toolbar
        :title="title"
        :bread-crumbs="breadCrumbs"
        refresh-button
        @refresh="refresh"
    >
      <template v-if="showExtension" v-slot:extension>
        <v-tabs
            v-if="extension == 'billing'"
            fixed-tabs
            show-arrows
            center-active
        >
          <v-tab :to="$store.state.user.isAdmin ? {name:'AdminWholesaleManagedBillingSummary', params:{customerId: resellerData.i_customer}} : { name: 'WholesaleManagedBillingSummary'}">Summary</v-tab>
          <v-tab :to="$store.state.user.isAdmin ? {name:'AdminWholesaleManagedBillingInvoices', params:{customerId: resellerData.i_customer}} : { name: 'WholesaleManagedBillingInvoices'}">
            Invoices
            <v-chip v-if="resellerData.unpaid_invoice_info && resellerData.unpaid_invoice_info.invoices && resellerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length" class="ml-2" small pill style="cursor: pointer;" color="error">{{resellerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length}}</v-chip>
            <v-chip v-else-if="resellerData.unpaid_invoice_info && resellerData.unpaid_invoice_info.total>0" small pill class="ml-2" style="cursor: pointer;" color="info">{{resellerData.unpaid_invoice_info.total}}</v-chip>
          </v-tab>
          <v-tab :to="$store.state.user.isAdmin ? {name:'AdminWholesaleManagedBillingTransactions', params:{customerId: resellerData.i_customer}} : { name: 'WholesaleManagedBillingTransactions'}">Transactions</v-tab>
        </v-tabs>
      </template>
    </Toolbar>
</template>

<script>
import Toolbar from '../templates/Toolbar';
import customerDataMixin from '../../../mixins/customerDataMixin';
import resellerDataMixin from "../../../mixins/resellerDataMixin";
export default {
    name: 'ResellerToolbar',
    mixins: [resellerDataMixin],
    components: { Toolbar },
    data: () => ({
    }),
    methods: {
        refresh() {
        },
    },
  props: {
    /*
      This values is used to determine where and how the tool bar is being used.
      Because of the logic used to layout the UI the toolbar is not manipulatable by the children.
      So we use this value to help determine what sub menu to output and to help determine any bread crumbs.
      Note that we would need to use global storage to pass anything to the toolbar from child pages if any information was needed.
      EG a device ID might be needed for the bread crumb route link pointing to the device summary page.
     */
    extension: {
      type: String,
      default: null,
    }
  },
    watch:{
      //clear previous addon breadcrumbs. Important is we are navigating to a page that does not set any addon breadcrumbs.
      $route (to, from){
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: null});
      },
    },
    computed: {
      showExtension( ){
        if(this.isloading){
          return false;
        }else if(this.extension == null){
          return false;
        }else if(this.extension == 'billing'){
          return true;
        }else{
          return false;
        }
      },
      breadCrumbs( ) {
        let ret = [];
        if(this.resellerData.name){
          if(this.$store.state.user.isAdmin){
            ret.push({
              name: 'AdminWholesaleHome',
              label: this.resellerData.name,
              params: {customerId: this.resellerData.i_customer}
            })
          }else {
            ret.push({
              name: 'WholesaleManagedHome',
              label: this.resellerData.name,
            })
          }
        }

        let addons = this.breadCrumbAddons;
          /**
           * check the current route and add it on.
           * The work for level 1 deep from the customer
           */

        if(this.$route.name != 'AdminWholesaleHome' && this.$route.name != 'WholesaleManagedHome'){
          //before we add the current route. Want to check if it already exists as an addon.
          let found = null;
          if (addons != null) {
            let self = this;
            addons.forEach(function (item) {
              if (item.name == self.$route.name) {
                found = item;
              }
            });
          }

          if(found == null) {
            ret.push({
              name: this.$route.name,
              label: this.$route.meta.title,
              params: this.$route.params
            })
          }
        }

        if (addons != null) {
          for (let i = 0; i < addons.length; i++) {
            ret.push(addons[i]);
          }
        }
        return ret;
      },
      breadCrumbAddons( ){
          return this.$store.state.data['breadCrumbsAddon'];
      },
      isloading( ){
        let loading = false;
        if(!this.resellerData.name){
          loading = true;
        }else if(this.fetchStoreLoading){
          loading = true;
        }
        return loading;
      },
      title() {
        return (!this.isloading ? this.$route.meta.title : 'Loading');
      },
    },
};
</script>
