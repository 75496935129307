<template>
    <Toolbar :title="title" refresh-button />
</template>

<script>
import Toolbar from '../templates/Toolbar';
export default {
    name: 'DefaultToolbar',
    components: { Toolbar },
    computed: {
        title() {
            return this.$route.meta.title || null;
        },
    },
};
</script>
