<template>
    <Toolbar :title="title" :bread-crumbs="breadCrumbs" refresh-button />
</template>

<script>
import Toolbar from '../templates/Toolbar';
import NewCustomerDialog from '../../dialogs/Customer/NewCustomerDialog';
import partnerDataMixin from '../../../mixins/partnerDataMixin';
export default {
    name: 'PartnerToolbar',
    components: { Toolbar },
    mixins: [partnerDataMixin],
    computed: {
        title() {
          return (!this.isloading ? this.$route.meta.title : 'Loading');
            //return (this.$vuetify.breakpoint.smAndUp ? (this.partnerData.companyname || 'Loading') + ' - ' : '') + (this.$route.meta.title || 'Partner');
        },
      breadCrumbs( ) {
        let ret = [];
        if(this.partnerData.companyname){
          ret.push({
            name:'ParentPartnerSummary',
            label:this.partnerData.companyname,
            params:{partnerId:this.partnerData.i_rep}
          })
        }

        let addons = this.breadCrumbAddons;

        /**
         * check the current route and add it on.
         * The work for level 1 deep from the customer
         */
        if(this.$route.name != 'ParentPartnerSummary'){
          ret.push({
            name:this.$route.name,
            label:this.$route.meta.title,
            params:this.$route.params
          })
        }
        if (addons != null) {
          for (let i = 0; i < addons.length; i++) {
            ret.push(addons[i]);
          }
        }
        return ret;
      },
      breadCrumbAddons( ){
        return this.$store.state.data['breadCrumbsAddon'];
      },
    },

};
</script>
